/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { addressToString, availabilityFromBinaryString, employeeTypeDictionary, formatPhoneNumber, formatTimeNew, payTypeDictionary, usdFormatter, validateInteger } from "../../../tools";

export default function OfferLetterPdf({offerLetterData}) {
    const parseOfferLetter = (text) => {
        text = text.replaceAll('[CompanyName]', offerLetterData.companyName);
        text = text.replaceAll('[CreatedDate]', moment().format('MMM D, YYYY'));
        text = text.replaceAll('[EmployeeName]', offerLetterData.employeeName);
        text = text.replaceAll('[EmployeeType]', employeeTypeDictionary[offerLetterData.employeeType]);
        text = text.replaceAll('[StartDate]', moment(offerLetterData.startDate).format('MMM D, YYYY'));
        text = text.replaceAll('[SupervisorName]', offerLetterData.supervisorName);
        text = text.replaceAll('[TerminalAddress]', addressToString(offerLetterData.terminalAddress));
        text = text.replaceAll('[StartTime]', formatTimeNew(offerLetterData.startTime));
        text = text.replaceAll('[Pay]', `${usdFormatter.format(offerLetterData.payRate)} ${payTypeDictionary[offerLetterData.payType]}`);
        text = text.replaceAll('[StopBonusThresholds]', offerLetterData.stopBonusWeekdayThreshold == offerLetterData.stopBonusWeekendThreshold ? `${validateInteger(offerLetterData.stopBonusWeekdayThreshold)} stops` : `${validateInteger(offerLetterData.stopBonusWeekdayThreshold)}/${validateInteger(offerLetterData.stopBonusWeekendThreshold)} (weekdays/weekends) stops`);
        text = text.replaceAll('[StopBonusAmounts]', offerLetterData.stopBonusWeekdayAmount == offerLetterData.stopBonusWeekendAmount ? usdFormatter.format(offerLetterData.stopBonusWeekdayAmount) : `${usdFormatter.format(offerLetterData.stopBonusWeekdayAmount)}/${usdFormatter.format(offerLetterData.stopBonusWeekendAmount)} (weekdays/weekends)`);
        text = text.replaceAll('[ExpectedAvailability]', availabilityFromBinaryString(offerLetterData.expectedAvailability));
        text = text.replaceAll('[DailyStartTime]', formatTimeNew(offerLetterData.dailyStartTime));
        text = text.replaceAll('[Benefits]', offerLetterData.benefits ?? 'There are currently no available benefits for this employer');
        text = text.replaceAll('[AOName]', offerLetterData.aoName);
        text = text.replaceAll('[AOPhoneNumber]', formatPhoneNumber(offerLetterData.aoPhoneNumber));
        text = text.replaceAll('[AOEmail]', offerLetterData.aoEmail);
        return text;
    }

    return (
        <Document>
            <Page size='LETTER' style={{padding: 50, position: 'relative'}}>
                <Text style={{fontSize: 11.5}}>{parseOfferLetter(offerLetterData.content)}</Text>

                <Text style={{fontSize: 11.5, position: 'absolute', bottom: 120, left: 50}}>Please sign and date to confirm receipt of this document.</Text>
                <View style={{position: 'absolute', left: 50, bottom: 50, display: 'flex', flexDirection: 'row', gap: 50, fontSize: 11.5}}>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Employee's Signature</Text>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Date</Text>
                </View>
            </Page>
        </Document>
    )
}