/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import HRUserPayEditor from './HRUserPayEditor';
import { payTypeDictionary, usdFormatter, ptoAccrualTypeDictionary, terminationTypeDictionary, employeeTypeDictionary } from '../../../tools';
import KeyValueRow from '../../../components/KeyValueRow';
import FileDownloadButton from '../../../components/FileDownloadButton';
import { getFileIcon } from '../../../tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper';
import QuickTable from '../../../components/QuickTable.js';
import Cookies from 'universal-cookie';

export default function HRUserPay({selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [payHistory, setPayHistory] = useState([]);
    const [selectedPay, setSelectedPay] = useState(undefined);
    const [terminationFiles, setTerminationFiles] = useState([]);
    const [futurePay, setFuturePay] = useState([]);
    const [effectivePay, setEffectivePay] = useState(null)
    const [modalSwitch, setModalSwitch] = useState('');
    const auth = new Cookies().get('auth');

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getPay', setIsLoading, (response) => {
            const nonEffectivePay = response.nonEffectivePay;
            let payHistory = nonEffectivePay.filter((pay)=>moment(pay.date).isSameOrBefore(moment(),'day')).sort((a, b) => {
                if (moment(a.date).isBefore(moment(b.date), 'days')) {
                    return 1;
                } else if (moment(a.date).isAfter(moment(b.date), 'days')) {
                    return -1;

                }else if(moment(a.date).isSame(moment(b.date),'day')){
                    if(a.uid < b.uid){
                        return 1;
                    } else {
                        return -1;
                    }
                } else {
                    return 0;
                }
            });

            let futurePay = nonEffectivePay.filter((pay)=>moment(pay.date).isAfter(moment(),'day')).sort((a, b) => {
                if (moment(a.date).isBefore(moment(b.date), 'days')) {
                    return 1;
                } else if (moment(a.date).isAfter(moment(b.date), 'days')) {
                    return -1;

                }else if(moment(a.date).isSame(moment(b.date),'day')){
                    if(a.uid < b.uid){
                        return 1;
                    } else {
                        return -1;
                    }
                } else {
                    return 0;
                }
            });

            setTerminationFiles(response.customDocuments);
            setEffectivePay(response.effectivePay);
            setPayHistory(payHistory);
            setFuturePay(futurePay);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send();
    }

    function handleAddPay(newPayInfo) {
        let payToAdjustInState;
        if(moment(newPayInfo.date).isSameOrBefore(moment()) && (effectivePay && moment(newPayInfo.date).isAfter(moment(effectivePay?.date)))){
            payToAdjustInState = effectivePay;
            setEffectivePay(newPayInfo);
        } else{
            payToAdjustInState = newPayInfo;
        }

        if(moment(payToAdjustInState.date).isAfter(moment())){
            const newArray = Array.from(futurePay);
            newArray.push(payToAdjustInState);
            setFuturePay(newArray.sort(sortPay))
        }else{
            const newArray = Array.from(payHistory);
            newArray.push(payToAdjustInState);
            setPayHistory(newArray.sort(sortPay));
        }
        hideModal();
    }

    function sortPay(a, b) {
        if (a.date < b.date) {
            return -1;
        } else if (a.date > b.date) {
            return 1;
        }
        return 0;   
    }

    function hideModal() {
        setModalSwitch('');
    }

    const historyElements = payHistory.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() => {setModalSwitch('preview'); setSelectedPay(item)}}>
                <td>{moment(item.date).format('MMM D, YYYY')}</td>
                <td>{item.title}</td>
                <td>{usdFormatter.format(item.payRate)}</td>
                <td>{payTypeDictionary[item.payType]}</td>
                <td>{usdFormatter.format(item.hourlyWage)}</td>
                <td>{employeeTypeDictionary[item.employeeType]}</td>
            </tr>
        )
    });

    const futureElements = futurePay.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() =>  {setModalSwitch('preview'); setSelectedPay(item)}}>
                <td>{moment(item.date).format('MMM D, YYYY')}</td>
                <td>{item.title}</td>
                <td>{usdFormatter.format(item.payRate)}</td>
                <td>{payTypeDictionary[item.payType]}</td>
                <td>{usdFormatter.format(item.hourlyWage)}</td>
                <td>{employeeTypeDictionary[item.employeeType]}</td>
            </tr>
        )
    });

    const terminationCard = !effectivePay ? (<h4 style={{textAlign: 'center'}}>No Effective Pay Or Termination</h4>) : (
        <>
            <Card style={{marginBottom: 16}}>
                <Card.Header>
                    <Card.Title>Effective Termination</Card.Title>
                </Card.Header>
                <Card.Body>
                   <HRUserTerminationPreview termination={effectivePay} terminationFiles={terminationFiles}/>
                </Card.Body>
            </Card>
        </>
    )
    
    const content = (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', gap: 12}}>
            { auth?.companyUserIdentifier &&
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <Button variant={'outline-primary'} onClick={() => {setModalSwitch('editor')}}>Create New Pay</Button>
                </div>
            }
            { futureElements.length > 0 && 
                <Card>
                    <Card.Header>
                        <Card.Title>Upcoming</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <QuickTable striped bordered hover headers={['Date', 'Title', 'Pay Rate', 'Pay Type', 'Hourly Wage', 'Employee Type']} rows={futureElements}/>
                    </Card.Body>
                </Card>
            }
            { effectivePay && effectivePay?.payType !== 'termination' ?
                <Card>
                    <Card.Header>
                        <Card.Title>Effective Pay</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 12}}>
                            <HRUserPayPreview pay={effectivePay}/>
                        </div>
                    </Card.Body>
                </Card>
            : 
                terminationCard
            }
            { historyElements.length > 0 && 
                <Card>
                    <Card.Header>
                        <Card.Title>Pay History</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <QuickTable striped bordered hover headers={['Date', 'Title', 'Pay Rate', 'Pay Type', 'Hourly Wage', 'Employee Type']} rows={historyElements}/>
                    </Card.Body>
                </Card>
            }
        </div>
    )

    const allPay = (effectivePay ? [effectivePay, ...payHistory, ...futurePay] : [...payHistory, ...futurePay]);
    
    return (
        <>
            <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
                {content}
            </LoadingWrapper>
            <Modal show={modalSwitch === 'editor'} onHide={hideModal} centered size='lg' fullscreen='lg-down'>
                <HRUserPayEditor companyUserIdentifier={selectedEmployee.companyUserUid} handleAddPay={handleAddPay} allPay={allPay.filter(pay => pay.payType !== 'termination')}/>
            </Modal>
            <Modal show={modalSwitch === 'preview'} onHide={hideModal} centered size='lg' fullscreen='md-down'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Viewing ${selectedPay?.payType !== 'termination' ? 'Pay' : 'Termination'}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { selectedPay?.payType !== 'termination'? 
                        <HRUserPayPreview pay={selectedPay}/>
                    :
                        <HRUserTerminationPreview termination={selectedPay} terminationFiles={terminationFiles}/>
                    }
                </Modal.Body>
            </Modal>
        </>
    )

}


export function HRUserPayPreview({pay}){

    const payData = [
        {title: 'Effective Date', value: moment(pay.date).format('MMM D, YYYY')},
        {title: 'Title', value: pay.title},
        {title: 'BWC Code', value: pay.bwcCode},
        {title: 'Pay Rate', value: usdFormatter.format(pay.payRate)},
        {title: 'Pay Type', value: payTypeDictionary[pay.payType]},
        {title: 'Hourly Wage', value: usdFormatter.format(pay.hourlyWage)},
        {title: 'PTO Accrual ' + ptoAccrualTypeDictionary[pay.ptoAccrualType], value: (pay.ptoAccrual?pay.ptoAccrual:0)},
        {title: 'Weekday Stop Threshold', value: (pay.stopBonusWeekdayThreshold ? pay.stopBonusWeekdayThreshold : 0)},
        {title: 'Weekend Stop Threshold', value: (pay.stopBonusWeekendThreshold ? pay.stopBonusWeekendThreshold : 0)},
        {title: 'Weekday Stop Wage Dollar Amount', value: (pay.stopBonusAmount ? usdFormatter.format(pay.stopBonusAmount) : usdFormatter.format(0))},
        {title: 'Weekend Stop Wage Dollar Amount', value: (pay.stopBonusWeekendAmount ? usdFormatter.format(pay.stopBonusWeekendAmount) : usdFormatter.format(0))},
        {title: 'Employee Type', value: employeeTypeDictionary[pay.employeeType]},
        {title: 'Medical Insurance', value: usdFormatter.format(pay.medical)},
        {title: 'Dental Insurance', value: usdFormatter.format(pay.dental)},
        {title: 'Vision Insurance', value: usdFormatter.format(pay.vision)},
        {title: 'Child Support', value: pay.childSupport && pay.childSupport !== 'Array' ? usdFormatter.format(JSON.parse(pay.childSupport).reduce((prev, curr) => {
            return prev + parseFloat(curr);
        }, 0)) : '$0.00'},
        {title: 'Automatic Reimbursements', value: pay.automaticReimbursement && pay.automaticReimbursement !== 'Array'? usdFormatter.format(JSON.parse(pay.automaticReimbursement).reduce((prev, curr) => {
            return prev + parseFloat(curr.amount);
        }, 0)) : '$0.00'},
        {title: 'Automatic Deductions (Flat Rate)', value: pay.automaticDeductions && pay.automaticDeductions !== 'Array'? usdFormatter.format(JSON.parse(pay.automaticDeductions).filter((d)=>d.label !== '401K (% of Gross)' && d.label !== 'Other (% of Gross)').reduce((prev, curr) => {
            return prev + parseFloat(curr.amount);
        }, 0)) : '$0.00'},
        {title: 'Automatic Deductions (% of Gross)', value: pay.automaticDeductions && pay.automaticDeductions !== 'Array' ? JSON.parse(pay.automaticDeductions).filter((d)=>d.label === '401K (% of Gross)' || d.label === 'Other (% of Gross)').reduce((prev, curr) => {
            return prev + parseFloat(curr.amount);
        }, 0) + '%' : '0%'},
    ];

    const payElements = payData.map((keyValuePair) => {
        return (
            <KeyValueRow isListItem key={keyValuePair.title} title={keyValuePair.title} value={keyValuePair.value}/>
        )
    });

    return (
        <ListGroup style={{flex: 1}}>
            {payElements}
        </ListGroup>
    )
}

export function HRUserTerminationPreview({termination, terminationFiles}){

    let terminationNotes = JSON.parse(termination.notes);
    const disciplinaryWriteups1 = terminationFiles.length > 0 && terminationNotes.disciplinaryRightups1 ? terminationFiles.find((file)=>file.uid == terminationNotes.disciplinaryRightups1.toString()) : {};
    const disciplinaryWriteups2 = terminationFiles.length > 0 && terminationNotes.disciplinaryRightups2 ? terminationFiles.find((file)=>file.uid == terminationNotes.disciplinaryRightups2.toString()) : {};
    const textsScreenshots1 = terminationFiles.length > 0 && terminationNotes.textsScreenshots1 ? terminationFiles.find((file)=>file.uid == terminationNotes.textsScreenshots1.toString()) : {};
    const textsScreenshots2 = terminationFiles.length > 0 && terminationNotes.textsScreenshots2 ? terminationFiles.find((file)=>file.uid == terminationNotes.textsScreenshots2.toString()) : {};
    const terminationLetter = terminationFiles.length > 0 && terminationNotes.terminationLetter ? terminationFiles.find((file)=>file.uid == terminationNotes.terminationLetter.toString()) : {};

    return (
        <>
            <ListGroup style={{marginBottom: 16}}>
                {terminationNotes.eligibleToRehire &&
                    <KeyValueRow isListItem title='Eligible To Rehire' value={terminationNotes.eligibleToRehire ? 'Yes' : 'No'} />
                }
                <KeyValueRow isListItem title='Effective Date' value={termination.date} />
                <KeyValueRow isListItem title='Termination Type' value={terminationTypeDictionary[termination.title]} />
                <KeyValueRow isListItem title='Termination Summary' value={terminationNotes.synopsis} />
                <KeyValueRow isListItem title='Complaints' value={terminationNotes.complaints}/>
            </ListGroup>
            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <h5 style={{textAlign: 'center'}}>Attachments</h5>
                {Object.keys(disciplinaryWriteups1).length !== 0 &&
                <Card style={{display: 'flex', flexDirection: 'row', flex: 1, marginTop:10}}>
                    <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                        <FontAwesomeIcon icon={getFileIcon(disciplinaryWriteups1.documentType)} style={{color: 'white'}}/>
                    </div>
                    <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                        <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>Disciplinary Write-Up #1: {disciplinaryWriteups1.documentName}</span>
                        <FileDownloadButton type='hr' target='downloadCustomDocument' data={{uid: terminationNotes.disciplinaryRightups1}} doc={disciplinaryWriteups1}/>
                    </div>
                </Card>
                }
                {Object.keys(disciplinaryWriteups2).length !== 0 &&
                <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                    <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                        <FontAwesomeIcon icon={getFileIcon(disciplinaryWriteups2.documentType)} style={{color: 'white'}}/>
                    </div>
                    <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                        <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>Disciplinary Write-Up #2: {disciplinaryWriteups2.documentName}</span>
                        <FileDownloadButton type='hr' target='downloadCustomDocument' data={{uid: terminationNotes.disciplinaryRightups2}} doc={disciplinaryWriteups2}/>
                    </div>
                </Card>
                }
                {Object.keys(textsScreenshots1).length !== 0 &&
                <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                    <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                        <FontAwesomeIcon icon={getFileIcon(textsScreenshots1.documentType)} style={{color: 'white'}}/>
                    </div>
                    <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                        <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>Texts/Screenshots #1: {textsScreenshots1.documentName}</span>
                        <FileDownloadButton type='hr' target='downloadCustomDocument' data={{uid: terminationNotes.textsScreenshots1}} doc={textsScreenshots1}/>
                    </div>
                </Card>
                }
                {Object.keys(textsScreenshots2).length !== 0 &&
                <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                    <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                        <FontAwesomeIcon icon={getFileIcon(textsScreenshots2.documentType)} style={{color: 'white'}}/>
                    </div>
                    <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                        <span style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis', width: '20%'}}>Texts/Screenshots #2: {textsScreenshots2.documentName}</span>
                        <FileDownloadButton type='hr' target='downloadCustomDocument' data={{uid: terminationNotes.textsScreenshots2}} doc={textsScreenshots2}/>
                    </div>
                </Card>
                }
                {Object.keys(terminationLetter).length !== 0 &&
                <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                    <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                        <FontAwesomeIcon icon={getFileIcon(terminationLetter.documentType)} style={{color: 'white'}}/>
                    </div>
                    <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                        <span style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis', width: '20%'}}>Termination Letter: {terminationLetter.documentName}</span>
                        <FileDownloadButton type='hr' target='downloadCustomDocument' data={{uid: terminationNotes.terminationLetter}} doc={terminationLetter}/>
                    </div>
                </Card>
                }
            </div>
        </> 
    )
}